/* eslint-disable react-hooks/exhaustive-deps */
import { Close, Favorite, Search } from "@mui/icons-material";
import {
    Badge,
    Fab,
    Grid,
    InputAdornment,
    TextField,
    Tooltip
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";
import { useIntl } from "react-intl";
import Icon from "../../../../components/Icon";

export default function SearchBar() {
    const intl = useIntl();
    const {
        fullMenu,
        allFavorites,
        setSearch,
        setFilteredItems,
        search,
        cartData
    } = useGlobalContext();
    const navigate = useNavigate();
    const data = fullMenu?.presenter_template;
    const [currentSearch, setCurrentSearch] = React.useState<string>("");
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";

    React.useEffect(() => {
        if (!currentSearch) {
            setSearch && setSearch("");
            setFilteredItems && setFilteredItems([]);
        }
    }, [currentSearch]);

    React.useEffect(() => {
        if (!search) {
            setCurrentSearch("");
        }
    }, [search]);

    React.useEffect(() => {
        setSearch && setSearch("");
        setFilteredItems && setFilteredItems([]);
        setCurrentSearch("");
    }, [location]);

    function filter() {
        setSearch && setSearch(currentSearch);
    }

    function handleKeypress(e: any) {
        if (e.key === "Enter") {
            filter();
        }
    }

    // Find the total cart items length
    const totalItemLength = cartData.reduce(
        (total, item: any) => total + item?.item_length,
        0
    );

    const [isSticky, setIsSticky] = useState(false);
    const [leftVal, setLeftVal] = useState(0);
    const cartButtonRef = useRef<HTMLDivElement | null>(null); // Define the ref with proper type

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isDemoOn = urlSearchParams.get("demo") === "on";
        const isSearch = urlSearchParams.get("s");
        if (isSearch) {
            setCurrentSearch(isSearch);
            setSearch && setSearch(isSearch);
        }
        const isDesktop = window.innerWidth >= 1024;
        const scrollableBlock = document.getElementById("root"); // Reference your scrollable block here
        const handleScroll = () => {
            const cartButtonLeftVal =
                cartButtonRef?.current?.getBoundingClientRect()?.right || 0;
            setLeftVal(cartButtonLeftVal - 40);

            const cartButtonOffset = cartButtonRef.current?.offsetTop || 0;
            const scrollPosition =
                !isDemoOn || !isDesktop
                    ? window.scrollY
                    : scrollableBlock?.scrollTop || 0;

            if (scrollPosition >= cartButtonOffset - 20) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        if (isDemoOn && isDesktop) {
            scrollableBlock?.addEventListener("scroll", handleScroll);
        }
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
        return () => {
            if (isDemoOn) {
                scrollableBlock?.removeEventListener("scroll", handleScroll); // Clean up the block's scroll listener
            }
            window.removeEventListener("resize", handleScroll);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Grid item xs={12} sx={{ zIndex: 2 }}>
            <Container maxWidth="sm">
                <Root
                    template={data}
                    className={classes.searchBarContainer}
                    ref={cartButtonRef}
                >
                    <TextField
                        size="small"
                        InputProps={{
                            inputProps: {
                                style: {
                                    fontFamily:
                                        fullMenu?.presenter_template?.fonts
                                            ?.search_text?.family || "Inter",
                                    color:
                                        fullMenu?.presenter_template?.fonts
                                            ?.search_text?.color || "#000000",
                                    fontSize:
                                        fullMenu?.presenter_template?.fonts
                                            ?.search_text?.size || "1.2rem",
                                    fontWeight:
                                        fullMenu?.presenter_template?.fonts
                                            ?.search_text?.weight || "400"
                                },
                                "aria-label": intl.messages[
                                    "items.search"
                                ] as string
                            },
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        marginRight: "-12px"
                                    }}
                                >
                                    {Boolean(currentSearch) && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            onClick={() => {
                                                setFilteredItems &&
                                                    setFilteredItems([]);
                                                setCurrentSearch("");
                                                setSearch && setSearch("");
                                            }}
                                        >
                                            <Close></Close>
                                        </div>
                                    )}
                                    <Fab
                                        size="small"
                                        className={classes.fabAdornament}
                                        onClick={filter}
                                        aria-label="Search Icon"
                                    >
                                        <Search></Search>
                                    </Fab>
                                </InputAdornment>
                            )
                        }}
                        placeholder={intl.messages["items.search"] as string}
                        className={classes.searchBar}
                        value={currentSearch}
                        onChange={(e) => setCurrentSearch(e.target.value)}
                        onKeyPress={handleKeypress}
                    ></TextField>
                    <Tooltip
                        title={
                            intl.messages[
                                isOrderOn ? "go_to_cart" : "go_to_favorites"
                            ] as string
                        }
                        onClick={() =>
                            isOrderOn
                                ? navigate(
                                      `/${fullMenu?.identifier}/cart?order=on`
                                  )
                                : navigate(`/${fullMenu?.identifier}/favorites`)
                        }
                        style={{
                            background:
                                fullMenu?.presenter_template.colors?.tertiary ||
                                "#FDECDF",
                            color:
                                fullMenu?.presenter_template.colors
                                    ?.secondary || "#F6C19F",
                            left: isSticky ? leftVal : ""
                        }}
                        className={`box ${isSticky ? "fixed-position" : ""}`}
                    >
                        <Fab size="small">
                            <Badge
                                badgeContent={
                                    !isOrderOn
                                        ? allFavorites?.length
                                        : totalItemLength
                                }
                                classes={{ badge: classes.badge }}
                            >
                                {isOrderOn ? (
                                    <Icon
                                        name="cart"
                                        className="cartIcon"
                                        style={{
                                            padding: "5px",
                                            width: "18px",
                                            height: "18px"
                                        }}
                                    />
                                ) : (
                                    <Favorite
                                        style={{
                                            padding: "5px 5px 5px 5px"
                                        }}
                                    ></Favorite>
                                )}
                            </Badge>
                        </Fab>
                    </Tooltip>
                </Root>
            </Container>
        </Grid>
    );
}
