import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";
import { useIntl } from "react-intl";
import Icon from "../../../../components/Icon";

export default function EmptyCart(props: { isCartPage: boolean }) {
    const { isCartPage } = props;
    const { fullMenu } = useGlobalContext();
    const data = fullMenu?.presenter_template;

    const intl = useIntl();

    return (
        <Root template={data} className={`${classes.container} no-favorites`}>
            <Icon name="emptyCart" className={classes.searchIcon} />
            <h5 className={classes.title}>
                {isCartPage
                    ? intl.formatMessage({
                          id: "cart.empty_title"
                      })
                    : intl.formatMessage({
                          id: "empty_search"
                      })}
            </h5>
            <p className={classes.content}>
                {isCartPage
                    ? intl.formatMessage({
                          id: "cart.empty_text"
                      })
                    : intl.formatMessage({
                          id: "empty_search_suggest"
                      })}
            </p>
        </Root>
    );
}
