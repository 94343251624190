import { Root, classes } from "./styles";
/* eslint-disable import/no-webpack-loader-syntax */
import { useGlobalContext } from "../../context/Global";
import { useEffect, useMemo, useState } from "react";
import { Radio, SwipeableDrawer } from "@mui/material";
import { languages } from "../../const/languages";
import { IFullMenu } from "../../const";
import { useTrackContext } from "../../context/Tracking";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/system";
import { Section as LocalSection } from "../../utils/sections";
import { Collections } from "../../utils/collection";
import Icon from "../../components/Icon";

export default function LanguageSelector(props: any) {
    const {
        language,
        fullMenu,
        setLanguage,
        setFullMenu,
        onChangeLanguage,
        checkoutStep,
        setCheckoutStep,
        setCartData,
        setCheckoutResponse,
        checkoutResponse
    } = useGlobalContext();
    const { restaurant: restaurantId } = useParams();
    const { newEvent } = useTrackContext();
    const [options, setOptions] = useState<string[]>([]);

    const data = {
        ...(fullMenu?.presenter_template?.language_selector || {}),
        ...(fullMenu?.presenter_template?.fonts?.language_selector || {})
    };
    const [showDrawer, setShowDrawer] = useState(false);
    const { available_languages, source_language } = fullMenu as IFullMenu;

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isLang = urlSearchParams.get("lang");
        if (!language)
            setLanguage(
                isLang &&
                    isLang in languages &&
                    available_languages?.includes(isLang)
                    ? isLang
                    : source_language
            );
    }, [language, source_language, setLanguage, available_languages]);

    const allLanguages = Object.keys(languages);

    let optionsAux = useMemo(() => {
        let opt = [...(available_languages || []), source_language || "en"];
        opt = opt.filter(
            (l, i) =>
                allLanguages.includes(l) && opt.findIndex((x) => x === l) === i
        );
        return opt;
    }, [available_languages, source_language, allLanguages]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isLang = urlSearchParams.get("lang");
        const now = Date.now();
        let optionCheckTimeout: NodeJS.Timeout | undefined;
        let stopLoop: NodeJS.Timeout | undefined;
        const ready = new Map<string, boolean>();
        const checkOptions = async () => {
            const desired = [...optionsAux];

            for (const lang of desired) {
                if (lang === source_language || ready.get(lang)) {
                    ready.set(lang, true);
                    continue;
                }
                let currentLanguage = `_${lang}`;
                if (lang === source_language) {
                    currentLanguage = "";
                }
                if (
                    isLang &&
                    isLang in languages &&
                    available_languages?.includes(isLang)
                ) {
                    currentLanguage = `_${isLang}`;
                }
                //Ping S3 to check if the translations are ready
                const res = await fetch(
                    `${process.env.REACT_APP_BUCKET_URL}/${restaurantId}/compiled${currentLanguage}.json?no-cache=${now}`,
                    { method: "HEAD" }
                );
                ready.set(lang, res.ok);
            }
            const temp = desired.filter((x) => ready.get(x));
            setOptions(temp);
            if (_.isEqual(temp, desired)) {
                clearTimeout(optionCheckTimeout);
            }
        };
        checkOptions();
        optionCheckTimeout = setInterval(checkOptions, 10000);
        stopLoop = setTimeout(() => {
            clearInterval(optionCheckTimeout);
        }, 600000);

        return () => {
            clearInterval(optionCheckTimeout);
            clearTimeout(stopLoop);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const handleLangChange = async (lang: any) => {
        onChangeLanguage(lang);
        setLanguage(lang);
        const now = Date.now();
        let currentLanguage = `_${lang}`;
        if (lang === source_language) {
            currentLanguage = "";
        }
        const langResponse = await window.fetch(
            `${process.env.REACT_APP_BUCKET_URL}/${restaurantId}/compiled${currentLanguage}.json?no-cache=${now}`
        );
        const langData = await langResponse.json();
        setFullMenu && setFullMenu(langData);
    };

    const languageLabel = (lang: string) => (
        <span>
            <FormattedMessage id={`languages.${lang}`} /> -{" "}
            <i>({languages[lang as keyof typeof languages]})</i>
        </span>
    );

    const navigate = useNavigate();
    const location = useLocation();
    const isCartPage =
        location.pathname.includes("/cart") ||
        location.pathname.includes("/favorites");

    const isCheckoutPage = location.pathname.includes("/checkout");

    const collectionId = new Collections(`${fullMenu?.identifier}`);
    const current_collection = collectionId.getcollection();

    const matchingObject = fullMenu?.collection_links.find(
        (item) => item?.collection_id?._id === current_collection
    );

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";

    const firstMenuId =
        matchingObject?.collection_id.menu_links[0]?.menu_id._id ||
        fullMenu?.collection_links[0]?.collection_id.menu_links[0]?.menu_id._id;

    const currentCollection =
        matchingObject?.collection_id._id ||
        fullMenu?.collection_links[0]?.collection_id._id;

    const navigateUrl = `/${
        fullMenu?.identifier
    }/${currentCollection}/${firstMenuId}${isOrderOn ? "?order=on" : ""}`;

    const sections = new LocalSection(`${fullMenu?.identifier}`);

    return (
        <>
            <Root props={data} className="rootWrapper">
                {isCartPage && (
                    <Box
                        className="backToMenu"
                        onClick={() => {
                            navigate(navigateUrl);
                            const sectionState = sections.getSections();
                            Object.keys(sectionState).forEach((id) => {
                                sections.setSectionState(id, false);
                            });
                        }}
                    >
                        <Icon name="backButton" />
                        <FormattedMessage id="back" />
                    </Box>
                )}

                {isCheckoutPage && (
                    <Box
                        className="backToMenu checkoutBackButton1"
                        // style={{
                        //     left: leftVal
                        // }}
                        onClick={() => {
                            if (checkoutStep !== 1) {
                                if (checkoutStep < 4) {
                                    setCheckoutStep((prevStep) => prevStep - 1);
                                } else {
                                    navigate(navigateUrl);
                                    setCheckoutStep(1);
                                    if (checkoutResponse) {
                                        // Clear set order data
                                        setCheckoutResponse(null);
                                        setCartData && setCartData([]);
                                    }
                                }
                            } else {
                                navigate(`/${fullMenu?.identifier}/cart`);
                            }
                        }}
                    >
                        <Icon name="backButton" />
                        <FormattedMessage
                            id={`${
                                checkoutStep < 4 ? "checkout_back" : "back"
                            }`}
                        />
                    </Box>
                )}

                <div
                    className={classes.buttonContainer}
                    style={{
                        ...(optionsAux.length < 2 ? { display: "none" } : {}),
                        background: props.bgColor || "transparent"
                    }}
                >
                    <button
                        className={classes.button}
                        onClick={() => setShowDrawer(true)}
                        style={
                            options.length < 2
                                ? { opacity: "0", pointerEvents: "none" }
                                : {}
                        }
                    >
                        <span className={classes.selectedLanguage}>
                            <Icon
                                name="languageSelect"
                                className={classes.svg}
                            />
                            {languages[language as keyof typeof languages] ||
                                "English"}
                        </span>
                    </button>
                </div>
            </Root>
            <SwipeableDrawer
                anchor="bottom"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                onOpen={() => setShowDrawer(true)}
                PaperProps={{ style: { backgroundColor: "transparent" } }}
                disableSwipeToOpen={true}
            >
                {" "}
                <Root props={data} className={classes.languageDrawer}>
                    <div className={classes.handle} />
                    <p className={classes.selectLanguageTitle}>
                        <FormattedMessage id="select_language" />
                    </p>
                    <div className={classes.optionsContainer}>
                        {options.map((lang, i) => (
                            <div
                                className={classes.option}
                                key={i}
                                onClick={() => {
                                    handleLangChange(lang);
                                    newEvent(
                                        "change-language",
                                        {
                                            language: lang,
                                            template: "1"
                                        },
                                        restaurantId || ""
                                    );
                                    setShowDrawer(false);
                                }}
                            >
                                <Radio
                                    checked={language === lang}
                                    className={classes.radio}
                                    inputProps={{
                                        "aria-label": lang
                                    }}
                                />
                                {languageLabel(lang)}
                            </div>
                        ))}
                    </div>
                </Root>
            </SwipeableDrawer>
        </>
    );
}
