/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/Global";
import LayoutOne from "../Components/Layout";
import { filterItems } from "../../../utils/filter";
import { IFullMenu, IItem } from "../../../const";
import { useTrackContext } from "../../../context/Tracking";
import { useNavigate } from "react-router-dom";
import { Root } from "./styles";
import { Box } from "@mui/system";
import EmptyCart from "../Components/EmptyCart";
import CartSection from "./CartSection";
import { getMobileOS } from "../../../utils/browser";
import { Section as LocalSection } from "../../../utils/sections";
import { Collections } from "../../../utils/collection";
import { CartItem } from "../../../utils/cartItems";
import { useIntl } from "react-intl";
import handleLocalStorage from "../../../utils/useLocalStorage";
import { languages } from "../../../const/languages";

export default function Cart1(props: any) {
    const {
        fullMenu,
        collection,
        search,
        showNotations,
        cartData,
        setCartData,
        setCheckoutStep,
        setCheckoutResponse
    } = useGlobalContext();
    const [filteredItems, setFiteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);
    const { newEvent } = useTrackContext();
    const navigate = useNavigate();
    const [modifyPopupPos, setModifyPopupPos] = useState<boolean>(false);

    const sections = new LocalSection(`${fullMenu?.identifier}`);
    const IsCheckout = fullMenu?.checkout_data?.checkout;
    const [loading, setLoading] = useState(false);
    const [allSkipped, setAllSkipped] = useState(false);

    const intl = useIntl();

    React.useEffect(() => {
        if (search?.length) {
            //If there is an active search, look for items in the entire collection
            let items: Array<{
                item_id: IItem;
            }> = [];

            // Filter over collections - menus - sections
            collection?.collection_id?.menu_links.forEach((_menu) => {
                _menu?.menu_id?.section_links?.forEach((section) => {
                    items = [
                        ...items,
                        ...filterItems(
                            section.section_id.item_links,
                            search || ""
                        )
                    ];
                });
            });

            return setFiteredItems(getUniqueItems(items));
        }
        // If there is not a search, just return the favorite ones
        setFiteredItems(getUniqueItems(getCartItems()));
    }, [search]);

    // This effect takes care of the background color of the body
    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;
    React.useEffect(() => {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }, []);

    React.useEffect(() => {
        newEvent(
            "bookmark-view",
            {
                template: "1",
                bookmark: getCartItems().length
            },
            fullMenu?.identifier || ""
        );
    }, []);

    function getUniqueItems(
        items: {
            item_id: IItem;
        }[]
    ): Array<{
        item_id: IItem;
    }> {
        return items.filter((v, i, a) => {
            return a.findIndex((t) => t.item_id._id === v.item_id._id) === i;
        });
    }
    function getCartItems(): Array<any> {
        const _favorites: any = [];
        fullMenu?.collection_links.forEach((col) => {
            col.collection_id.menu_links.forEach((men) => {
                men.menu_id.section_links.forEach((sec) => {
                    sec.section_id.item_links.forEach((it) => {
                        cartData?.length > 0 &&
                            cartData?.forEach((item: any) => {
                                if (item._id === it.item_id?._id) {
                                    _favorites.push(it);
                                }
                            });
                    });
                });
            });
        });

        return _favorites;
    }

    function clearfavorites() {
        setCartData && setCartData([]);
        const cartItem = new CartItem(`${fullMenu?.identifier}`);
        const collectionId = new Collections(`${fullMenu?.identifier}`);
        const current_collection = collectionId.getcollection();
        cartItem.clearCartItem();
        setFiteredItems([]);

        const sectionState = sections.getSections();
        Object.keys(sectionState).forEach((id) => {
            sections.setSectionState(id, false);
        });

        newEvent(
            "clear-bookmark",
            {
                template: "1"
            },
            fullMenu?.identifier || ""
        );

        const matchingObject = fullMenu?.collection_links.find(
            (item) => item?.collection_id?._id === current_collection
        );

        const firstMenuId =
            matchingObject?.collection_id.menu_links[0]?.menu_id._id ||
            fullMenu?.collection_links[0]?.collection_id.menu_links[0]?.menu_id
                ._id;

        const currentCollection =
            matchingObject?.collection_id._id ||
            fullMenu?.collection_links[0]?.collection_id._id;

        const navigateUrl = `/${fullMenu?.identifier}/${currentCollection}/${firstMenuId}?order=on`;

        return navigate(navigateUrl);
    }
    const data = fullMenu?.presenter_template;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isWhatsapp = urlSearchParams.get("app") === "wap";
    const isSession = urlSearchParams.get("session");
    const isLang = urlSearchParams.get("lang");
    const isLoc = urlSearchParams.get("loc");
    const { available_languages, source_language } = fullMenu as IFullMenu;

    const currentLang =
        isLang && isLang in languages && available_languages?.includes(isLang)
            ? isLang
            : source_language;

    const orderMessage = cartData
        ?.map((item: any, index, array) => {
            const trimmedString = item.item_message.replace(/^\s+|\s+$/g, "");
            return `${item.item_length} x ${item.display_name}${
                trimmedString !== "" ? ` (${trimmedString})` : ""
            }${
                index === array.length - 1
                    ? ""
                    : index === array.length - 2
                    ? " and\n"
                    : ",\n"
            }`;
        })
        .join("");
    const phone_number = fullMenu?.ohwaiter_phone_number?.replace(/\s/g, "");
    const deviceType = getMobileOS();

    const messageType = isWhatsapp ? "https://wa.me/" : "sms:";
    const bodyType = isWhatsapp ? "text=" : "body=";
    const separator = isWhatsapp ? "?" : deviceType === "iOS" ? "&" : "?";

    const smsData = `${messageType}${phone_number}${separator}${bodyType}${encodeURIComponent(
        `${intl.formatMessage({
            id: "cart.pre_message_text"
        })}\n${orderMessage}.`
    )}`;

    // Handle sessionData
    const { setItem, removeItem } = handleLocalStorage();

    // Handle Checkout when all skipped
    const handleComplete = async (sessionData: any = {}) => {
        setLoading(true);
        const body = {
            instance_id: fullMenu?.instance,
            session: sessionData?.short_code,
            location: isLoc ? isLoc : sessionData?.location,
            name: sessionData?.name,
            memberID: sessionData?.member_id,
            phone: sessionData?.phone?.number,
            language: currentLang,
            country_code: sessionData?.phone?.country_code,
            order: cartData.map((item: any) => {
                return {
                    item: item?.display_name,
                    quantity: item?.item_length,
                    notes: item?.item_message
                };
            })
        };
        try {
            // Replace this with your actual API endpoint
            const response = await fetch(
                `${process.env.REACT_APP_TRACK_URL}/cart/place-order/${fullMenu?.identifier}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                }
            );

            const data = await response.json();

            if (response.ok) {
                setCheckoutStep(4);
                setCheckoutResponse(data);
                navigate(`/${fullMenu?.identifier}/checkout`);
            } else {
                // Check if there are validation errors in the response
                if (data.message && Array.isArray(data.message)) {
                    const errorMessages = data.message
                        .map((err: any) => {
                            if (typeof err === "object" && err.message) {
                                return err.message;
                            }
                            return String(err);
                        })
                        .join(". ");
                    throw new Error(errorMessages);
                } else {
                    throw new Error(data.message || "Failed to submit order");
                }
            }
        } catch (err: any) {
            if (typeof err === "object" && err.message) {
                alert(err.message);
            } else {
                alert("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    // Check if all the steps are skipped
    useEffect(() => {
        const checkoutData = fullMenu?.checkout_data;

        const isAllSkipped = !!(
            checkoutData?.guest_location?.status === "inactive" &&
            checkoutData?.guest_name?.status === "inactive" &&
            checkoutData?.guest_member_id?.status === "inactive" &&
            checkoutData?.guest_phone?.status === "inactive"
        );

        setAllSkipped(isAllSkipped);
    }, [fullMenu]);

    // Handle Checkout button click
    const handleCheckout = async () => {
        setLoading(true);
        try {
            // If the cart has items and there's a session in the URL
            if (cartData.length > 0 && isSession) {
                // Fetch session data from API
                const response = await fetch(
                    `${process.env.REACT_APP_TRACK_URL}/cart/get-sessions-details?sessionId=${isSession}&identifier=${fullMenu?.identifier}`
                );

                if (!response.ok) {
                    // Navigate to the checkout page
                    navigate(`/${fullMenu?.identifier}/checkout`);

                    // If response is not ok, throw an error
                    const errorData = await response.json();
                    throw new Error(
                        errorData.message || "Failed to fetch session data"
                    );
                }

                const responseData = await response.json();

                // Store session data in localStorage
                if (!responseData?.data?.code) {
                    setItem("sessionData", responseData?.data);
                }
                const checkoutData = fullMenu?.checkout_data;

                const isAllSkipped = !!(
                    (checkoutData?.guest_location?.status === "inactive" ||
                        (responseData?.data?.location &&
                            checkoutData?.guest_location?.skip_if_known)) &&
                    (checkoutData?.guest_name?.status === "inactive" ||
                        (responseData?.data?.name &&
                            checkoutData?.guest_name?.skip_if_known)) &&
                    (checkoutData?.guest_member_id?.status === "inactive" ||
                        (responseData?.data?.member_id &&
                            checkoutData?.guest_member_id?.skip_if_known)) &&
                    (checkoutData?.guest_phone?.status === "inactive" ||
                        (responseData?.data?.phone?.number &&
                            checkoutData?.guest_phone?.skip_if_known))
                );

                if (isAllSkipped) {
                    handleComplete(responseData?.data);
                } else {
                    // Navigate to the checkout page
                    navigate(`/${fullMenu?.identifier}/checkout`);
                }
            } else if (cartData.length > 0) {
                removeItem("sessionData");
                // If no session, just navigate to checkout
                if (allSkipped) {
                    handleComplete();
                } else {
                    // Navigate to the checkout page
                    navigate(`/${fullMenu?.identifier}/checkout`);
                }
            } else {
                console.log("Cart is empty");
            }
        } catch (error) {
            console.error("Failed to fetch session data", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch
            showNavbar
            showNotationToggle
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container
                maxWidth="md"
                style={{ padding: "0 0 0 0", position: "relative" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            className="section-wrapper"
                            sx={{
                                position: "relative",
                                zIndex: modifyPopupPos ? 2 : 1,
                                paddingBottom:
                                    Boolean(filteredItems.length) && !search
                                        ? "93px"
                                        : 0
                            }}
                        >
                            <CartSection
                                sectionTitle={
                                    search
                                        ? intl.formatMessage({
                                              id: "items.search_result"
                                          })
                                        : intl.formatMessage({
                                              id: "cart.title"
                                          })
                                }
                                sectionDescription=""
                                defaultExpanded
                                alwaysExpanded
                                showNotations={showNotations}
                                items={filteredItems}
                                id="cart-section"
                                internalName="cart-section"
                                template={fullMenu?.presenter_template || {}}
                                setModifyPopupPos={setModifyPopupPos}
                            />
                        </Box>
                        {/* If there are favorites, show the option to remove all */}
                        {Boolean(filteredItems.length) && !search && (
                            <Root template={data} className="btnWrapper">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="cartBtn clearCartBtn"
                                    onClick={clearfavorites}
                                >
                                    {intl.formatMessage({
                                        id: "cart.clear"
                                    })}
                                </Button>
                                {IsCheckout ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCheckout}
                                        className="cartBtn orderBtn"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            intl.formatMessage({
                                                id: "checkout.checkout"
                                            })
                                        )}
                                    </Button>
                                ) : (
                                    <a
                                        href={smsData}
                                        rel="noreferrer"
                                        className="cartBtn orderBtn"
                                        target="_blank"
                                        onClick={() => {
                                            setTimeout(() => {
                                                clearfavorites();
                                            }, 1000);
                                        }}
                                    >
                                        {intl.formatMessage({
                                            id: "cart.text_order"
                                        })}
                                    </a>
                                )}
                            </Root>
                        )}
                        {/* There are not favorites yet */}
                        {!Boolean(filteredItems.length) && !search && (
                            <EmptyCart isCartPage></EmptyCart>
                        )}
                        {/* There are not results of searching */}
                        {!Boolean(filteredItems.length) && search && (
                            <EmptyCart isCartPage={false}></EmptyCart>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </LayoutOne>
    );
}
