import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as PinterestIcon } from "../assets/icons/pinterest.svg";
import { ReactComponent as YelpIcon } from "../assets/icons/yelp.svg";
import { ReactComponent as TiktokIcon } from "../assets/icons/tiktok.svg";
import { ReactComponent as LanguageSelectIcon } from "../assets/icons/language.svg";
import { ReactComponent as BackButton } from "../assets/icons/backButton.svg";
import { ReactComponent as AddToCart } from "../assets/icons/addtocart.svg";
import { ReactComponent as EditIcon } from "../assets/icons/editIcon.svg";
import { ReactComponent as Delete } from "../assets/icons/trash.svg";
import { ReactComponent as Checked } from "../assets/icons/cheked.svg";
import { ReactComponent as Cart } from "../assets/icons/cart.svg";
import { ReactComponent as NextButton } from "../assets/icons/next-arrow.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning-icon.svg";
import { ReactComponent as EmtyCart } from "../assets/icons/emptyCart.svg";
import { ReactComponent as NoSearchIcon } from "../assets/icons/failedsearch.svg";

const iconTypes = {
    noSearch: NoSearchIcon,
    emptyCart: EmtyCart,
    warning: WarningIcon,
    next: NextButton,
    cart: Cart,
    checked: Checked,
    delete: Delete,
    edit: EditIcon,
    addToCart: AddToCart,
    backButton: BackButton,
    languageSelect: LanguageSelectIcon,
    twitterIcon: TwitterIcon,
    instagramIcon: InstagramIcon,
    facebookIcon: FacebookIcon,
    pinterestIcon: PinterestIcon,
    yelpIcon: YelpIcon,
    tiktokIcon: TiktokIcon
};

const Icon = ({ name, ...props }) => {
    const Icon = iconTypes[name];
    return Icon ? <Icon {...props} /> : <></>;
};

export default Icon;
